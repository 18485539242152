.security {
  background-color: $main-medium;
  padding-bottom: 180px;
}

.security-text {
  max-width: 800px;
  margin: 0 auto;
  color: $white;

  @include tablet-max {
    margin-left: 40px;
    margin-right: 40px;
  }
}

.security-box {
  background-color: $white;
  padding: 30px 35px;
  border-radius: 8px;
  text-align: center;
  margin-top: 50px;

  img {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }

  @include tablet-max {
    margin-top: 20px;
    margin-left: 40px;
    margin-right: 40px;
  }

  @include ipad {
    padding: 20px;
    margin-top: 30px;
  }
}