//common styles for menu
.nav-main,
.second-menu-box {
  a {
    text-transform: capitalize;
    position: relative;
    font-weight: $font-semi;

    &.active {

      &::after {
        display: block;
        content: ' ';
        position: absolute;
        height: 3px;
        left: 0;
        right: 0;
        bottom: -3px;
      }
    }
  }
}

.second-menu {
  position: absolute;
  max-height: 0;
  min-height: 0;
  transition: all .3s ease-out;
  overflow-y: scroll;
  top: 0;
  left: 0;
  right: 0;
  z-index: 102;

  &.open {
    max-height: 100vh;
    min-height: 100vh;
    transition: all .3s ease-in;
    position: fixed;
    top: 0;
    right: 0;
    background-color: rgba($base, 0.3);
  }
}

.nav-mobile-btn {
  display: inline-block;
  padding: 0;
  @include size(45px, 38px);
  text-align: center;
  position: relative;
  background-color: transparent;
  z-index: 14;

  span {
    display: block;
    position: absolute;
    right: 0;
    margin-right: 6px;
    height: 3px;
    width: 65%;
    background-color: $base;

    &:nth-child(1) {
      top: 10px;
    }

    &:nth-child(2) {
      top: 18px;
    }

    &:nth-child(3) {
      top: 26px;
    }
  }

  &.open {
    span {
      background-color: $white;

      &:nth-child(1) {
        transform: rotate(45deg);
        top: 18px;
      }

      &:nth-child(2) {
        width: 0;
        opacity: 0;
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
        top: 18px;
      }
    }
  }
}


.header {
  .nav-main {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 30px;

    .active {
      color: $main;
    }

    li {
      margin-left: 40px;

      &:first-child {
        margin-left: 0;
      }

      @include laptop-max {
        margin-left: 20px;

        a {
          font-size: 14px;
        }
      }

      @include tablet-max {
        display: none;
      }
    }
  }
}

.nav-menu {
  display: flex;
  flex: 1;
}

.second-menu-box {
  background-color: $main;
  width: 380px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 2px 20px 0 rgba(0,0,0,0.5);
  padding: 35px 30px 30px 50px;

  @include tablet-max {
    width: 100%;
  }

  .nav-main {
    flex-direction: column;

    @include tablet {
      display: none;
    }
  }

  a {
    color: $white;
    font-size: 24px;
    padding-top: 15px;
    padding-bottom: 15px;
    display: block;

    &.active {
      color: $blue;
    }

    @include tablet-max {
      font-size: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.second-menu-bottom {
  margin-top: 20px;
  padding: 20px 0;
  border-top: 1px solid rgba($white, .5);
  border-bottom: 1px solid rgba($white, .5);
}

.nav-close-btn {
  display: inline-block;
  padding: 0;
  @include size(45px, 38px);
  text-align: center;
  position: relative;
  background-color: transparent;

  span {
    display: block;
    position: absolute;
    right: 0;
    margin-right: 6px;
    height: 3px;
    width: 65%;
    background-color: $white;

    &:nth-child(1) {
      transform: rotate(45deg);
      top: 18px;
    }

    &:nth-child(2) {
      transform: rotate(-45deg);
      top: 18px;
    }
  }
}
