.subscription-title-box {
  font-size: 18px;
  padding-right: 20px;
  padding-top: 30px;

  @include tablet-max {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 30px;
    text-align: center;
  }
}

.trial {
  font-weight: 800;
  text-decoration: underline;
  margin-top: 15px;
  display: inline-block;

  &:hover {
    text-decoration: none;
  }
}

.subscription-individual-box {
  background-color: $main;
  color: $white;
  border-radius: 6px;
  height: 100%;
  padding-top: 10px;

  li.check {
    background: url('../images/сheck-white.svg') no-repeat left 3px;
  }

  @include tablet-max {
    margin-bottom: 30px;
  }

  h5 b {
    color: $white;
  }

  .trial {
    color: $white;
  }
}

.subscription-family-box {
  background-color: $white;
  border-radius: 6px;
  height: 100%;
  padding-top: 10px;
  border: solid 1px $green-light;

  li {
    background: url('../images/сheck-green.svg') no-repeat left 3px;
  }

  h5 b {
    color: $base;
  }

  .trial {
    color: $main;
  }
}

.subscription-padding {
  padding: 30px 30px 35px;

  @include ipad {
    padding: 20px;

    h5 {
      font-size: 20px;
      letter-spacing: 1px;
    }
  }
}

.subscription-subtitle {
  font-size: 14px;
  line-height: 1.2;
  display: inline-block;
}

.line-white {
  border-bottom: 1px solid $white;
}

.line-green {
  border-bottom: 1px solid $green-light;
}

.subscription-ul {
  li {
    margin-bottom: 15px;
    padding-left: 25px;
  }
}

.subscription-button {
  margin-top: auto;

  .button {
    width: 100%;
  }

  @include ipad {
    &.pr-15 {
      padding-right: 0;
    }

    &.pl-15 {
      padding-left: 0;
    }
  }
}

.subscription-trigger {
  background: $main-medium;
  height: 32px;
  width: 55px;
  border-radius: 16px;
  position: relative;
  cursor: pointer;
  transition: background .2s;
  margin-left: 10px;
  margin-right: 10px;

  &:hover {
    background: $main;
  }

  &.monthly .trigger {
    right: auto;
    left: 4px;
  }

  &.annual .trigger {
    right: 4px;
    left: auto;
  }
}

.trigger {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  top: 4px;
  background-color: $white;
}

.subscription-save {
  margin-top: 15px;
  color: $main-medium;
  font-weight: 800;
  font-style: italic;
}

.subscription-plan {
  font-weight: 800;
  font-size: 18px;
  color: $blue-light;
  transition: color .2s;

  &.active {
    color: $base;
  }
}

.individual-price,
.family-price {
  position: relative;
}

.save-20 {
  color: $main-light;
  font-style: italic;
  font-weight: 800;
  font-size: 14px;
  position: absolute;
  top: -20px;
  width: 90px;
  right: 0;
  letter-spacing: normal;
}
