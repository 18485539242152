$button-padding: 5px 10px;

.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  display: inline-block;
  color: $link;
  text-align: center;
  font-size: $font-size-base;
  text-decoration: none;
  white-space: nowrap;
  position: relative;
  border-radius: $radius;
  transition: background $anim, box-shadow $anim, color $anim;
  padding: $button-padding;
  border-radius: 30px;
  line-height: 56px;
  padding: 0 30px;
  min-width: 160px;
  color: $main;
  font-weight: 700;

  &:hover,
  &:active,
  &:focus {
    outline: 0;
    cursor: pointer;
  }

  &.upper {
    text-transform: uppercase;
  }

  &.transparent {
    box-shadow: 0 0 0px 2px $main-light inset;
    width: 250px;

    &:hover {
      box-shadow: 0 0 0px 2px $main inset;
    }
  }

  &.main {
    color: $white;
    background-size: 100% 1px;
    background-image: linear-gradient(to right, $main , $main-light);

    &:hover {
      background-size: 140% 1px; 
    }
  }

  &.white {
    color: $main;
    background-color: $white;

    &:hover {
      background-color: $grey-light;
    }
  }
}

a.button-cta, .main.button-cta {
  background-image: linear-gradient(to right, $cta , $cta-light);
  width: 250px;
  font-size: 1.5em;
}