/*------------- headings ---------------*/

.large,
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0;
  padding: 0;
}

h1, .h1 {
  margin: 0;
  margin-bottom: 15px;
  @include tablet {
    font-size: $font-size-big; 
  }
}

h2,
h3,
h4,
h5 {
  font-weight: 600;
  letter-spacing: 1.4px;
  line-height: 1.2;

  b {
    color: $main-medium;
    font-weight: 800;
  }
}

h2 {
  font-size: 56px;

  @include tablet-max {
    font-size: 2.5em;
  }
}

h3 {
  font-size: 48px;

  @include tablet-max {
    font-size: 2em;
  }
}

h4 {
  font-size: 36px;

  @include tablet-max {
    font-size: 1.5em;
  }
}

h5 {
  font-size: 24px;

  @include tablet-max {
    font-size: 20px;
  }
}
