.features {
  font-size: 18px;

  ul {
    padding: 20px 0 25px 0;
  }

  li {
    padding: 10px 0 10px 46px;
    background: url('../images/boolet.svg') no-repeat left 11px;
  }
}

.features-text {
  max-width: 680px;
  margin: 0 auto;
}

.features-img {
  @include tablet-max {
    border-radius: 10px;
    overflow: hidden;
  }
}