.moments-text {
  max-width: 880px;
  margin: 10px auto 30px auto;
}

.moments-carusel {
  padding-top: 20px;
  height: 370px;
  width: 100%;
  margin: 20px auto 0;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  overflow: hidden;

  @include tablet-max {
    height: 250px;
  }

  div {
    background-image: url(../images/photos-line.png);
    background-position: top left;
    width: 4589px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    height: 545px;
    background-position-x: 0;
    animation: moveSlideshow 175s linear infinite;

    @include tablet-max {
      background-image: url(../images/photos-line-m.png);
      width: 3200px;
      height: 380px;
      animation: moveSlideshowMobile 175s linear infinite;
    }
  }

  @include tablet-max {
    height: 250px;
  }
}

@keyframes moveSlideshow {
  100% { 
    background-position-x: -4589px
  }
}

@keyframes moveSlideshowMobile {
  100% { 
    background-position-x: -3200px
  }
}