.top-screen {
  padding-top: 120px;
  min-height: 100vh;
}

.top-paragraph {
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;
}

.dynamic-title {
  margin-left: auto;
  margin-right: auto;
  text-align: right;
  height: 68px;

  b {
    background-color: $green-title;
    padding-left: 10px;

    span {
      color: $white !important;
    }
  }

  @include tablet-max {
    text-align: center;
    height: 110px;

    b {
      display: inline-block;
    }

    .second-word {
      display: block;
    }
  }
}

@include tablet-max {
  .top-paragraph {
    margin-top: 10px;
    text-align: left;
  }
  .about .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .phone-img {
    margin-right: 18vw;
  }
}
