.header {
  position: fixed;
  width: 100%;
  z-index: 100;
  background-color: transparent;
  transition: background-color .3s;

  &.white {
    background-color: $white;
    box-shadow: 0 2px 6px 0 rgba(41,33,74,0.1);

    .header__container {
      padding-top: 20px;
      padding-bottom: 20px;

      @include laptop-max {
        padding: 15px 20px;
      }
    }
  }
}

.header__container {
  padding: 30px 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: padding .4s;

  @include laptop-max {
    padding: 15px 20px;
  }
}
