*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-size: $font-size-sm;
  font-weight: 400;
  color: $base;
  background-color: $white;
  -webkit-overflow-scrolling: touch;
  font-family: $font-family-base;
  background-color: $white;
  line-height: 1.4;

  @include mobile {
    font-size: $font-size-base;
  }

  &.modal-open {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;

  &,
  &:hover,
  &:focus,
  &:active {
    outline:none;
  }
}

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

img {
  vertical-align: middle;
  border-style: none;
  @include img-responsive();
}


button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  box-shadow: none;
}

b {
  font-weight: $font-bold;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin-top: 0;
  margin-bottom: 1.5em;
}

.container {
  @include container();

  @include desktop {
    max-width: $container-desktop;
  }

  @include tablet-max {
    max-width: $container-desktop-s;
  }
}

.container-half {
  @include container-half();

  @include desktop {
    max-width: $container-desktop-half;
  }

  @include tablet-max {
    max-width: $container-desktop-half-s;
  }
}

.bg-grey {
  background-color: $grey-light;
}

.d-flex {
  display: flex !important;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.direction-column {
  flex-direction: column;
}

.text-big {
  font-size: 24px;
  line-height: 1.3;

  @include tablet-max {
    font-size: 20px;
  }
}

.text {
  font-size: 20px;
  line-height: 1.3;

  @include tablet-max {
    font-size: 18px;
  }
}

.text-small {
  font-size: 14px;
  line-height: 1.3;
}

.ml-5 {
  margin-left: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.pt-10 {
  padding-top: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.pl-15 {
  padding-left: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-70 {
  margin-top: 70px;
}

.mb-20 {
  margin-bottom: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pt-big {
  padding-top: 80px;

  @include tablet-max {
    padding-top: 40px;
  }
}

.pb-big {
  padding-bottom: 80px;

  @include tablet-max {
    padding-bottom: 40px;
  }
}

.pt-medium {
  padding-top: 80px;

  @include tablet-max {
    padding-top: 50px;
  }
}

.pb-medium {
  padding-bottom: 80px;

  @include tablet-max {
    padding-bottom: 50px;
  }
}

.pl-medium {
  padding-left: 70px;

  @include tablet-max {
    padding-left: 15px;
  }
}

.pr-medium {
  padding-right: 70px;

  @include tablet-max {
    padding-right: 15px;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.d-none {
  display: none;
}

@include tablet-max {
  .d-none-s {
    display: none;
  }

  .d-block-s {
    display: block;
  }

  .direction-column-s {
    flex-direction: column;
  }

  .direction-column-reverse {
    flex-direction: column-reverse;
  }

  .justify-center-s {
    justify-content: center;
  }

  .order-1-s {
    order: 1
  }

  .order-2-s {
    order: 2
  }

  .pl-0-s {
    padding-left: 0;
  }
  
  .pr-0-s {
    padding-right: 0;
  }

  .pt-0-s {
    padding-top: 0;
  }

  .pl-10-s {
    padding-left: 10px;
  }
  
  .pr-10-s {
    padding-right: 10px;
  }

  .pt-20-s {
    padding-top: 20px;
  }

  .pl-15-s {
    padding-left: 15px;
  }
  
  .pr-15-s {
    padding-right: 15px;
  }

  .pl-10-s {
    padding-left: 10px;
  }
  
  .pr-10-s {
    padding-right: 10px;
  }

  .mb-0-s {
    margin-bottom: 0;
  }

  .mr-auto-s {
    margin-right: auto;
  }

  .ml-auto-s {
    margin-left: auto;
  }

  .mt-10-s {
    margin-top: 10px;
  }

  .mb-10-s {
    margin-bottom: 10px;
  }

  .mb-20-s {
    margin-bottom: 20px;
  }

  .mt-20-s {
    margin-top: 20px;
  }
}

.width-box {
  margin-left: -10px;
  margin-right: -10px;

  .width-33 {
    width: calc(100% / 3);
    padding-left: 10px;
    padding-right: 10px;

    @include tablet-max {
      width: 100%;
    }
  }
}

.link {
  color: $green-title;
  text-decoration: none;
}
