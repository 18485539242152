@mixin mobile {
  @media screen and (min-width: $mobile) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: $tablet) {
    @content;
  }
}

@mixin tablet-max {
  @media screen and (max-width: $tablet - 1) {
    @content;
  }
}

@mixin ipad {
  @media screen and (min-width: $tablet) and (max-width: $laptop) {
    @content;
  }
}

@mixin laptop {
  @media screen and (min-width: $laptop) {
    @content;
  }
}

@mixin laptop-max {
  @media screen and (max-width: $laptop - 1) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop) {
    @content;
  }
}

@mixin desktop-lg {
  @media screen and (min-width: $desktop-lg) {
    @content;
  }
}

@mixin desktop-2k {
  @media screen and (min-width: $desktop-2k) {
    @content;
  }
}

// iPhone 5, 5c, 5s, SE
@mixin iphone-5{
  @media screen and (device-aspect-ratio: 40/71) {
    @content;
  }
}

// iPhone 6, 6s, 7, 8
@mixin iphone-6{
  @media screen and (device-aspect-ratio: 375/667) {
    @content;
  }
}

// iPhone 6+, 7+, 8+
@mixin iphone-6plus{
  @media only screen and (min-device-width : 414px) and (max-device-width : 736px) and (-webkit-min-device-pixel-ratio : 3) and (-webkit-device-pixel-ratio : 3) {
    @content;
  }
}

//iPhone X
@mixin iphone-x {
  @media only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3) {
    @content;
  }
}

@mixin ie {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

@mixin container {
  position: relative;
  width: 100%;
  padding-left: $padding;
  padding-right: $padding;
  margin-left: auto;
  margin-right: auto;

  @include tablet-max {
    padding-left: $padding-s;
    padding-right: $padding-s;
  }
}

@mixin  container-half {
  width: 100%;
  padding-left: $padding;
  padding-right: $padding;

  @include tablet-max {
    padding-left: $padding-s;
    padding-right: $padding-s;
  }
}

@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin absolute-center-all {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin absolute-center-hor {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin absolute-center-vert {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin absolute-fullsize {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin img-responsive($display: block) {
  display: $display;
  max-width: 100%;
  height: auto;
}

@mixin placeholder-color($color) {
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
}

@mixin placeholder {
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin row-divider($items, $space) {
  width: calc(100% / #{$items} - #{$space}*2);
  padding-bottom: calc(100% / #{$items} - #{$space}*2);
}

@mixin slide-triangle-left {
  content: ' ';
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  position: absolute;
  top: 50%;
  left: 50%;
  box-shadow: inset 0 1px 0 0 $shadow;
  margin-left: -20px;
  border-bottom: 12px solid transparent;
  border-top: 12px solid transparent;
  border-right: 20px solid #acc4dd;
}

@mixin slide-triangle-right {
  content: ' ';
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  position: absolute;
  top: 50%;
  left: 50%;
  box-shadow: inset 0 1px 0 0 $shadow;
  margin-left: 0;
  border-bottom: 12px solid transparent;
  border-top: 12px solid transparent;
  border-left: 20px solid #46c7d7;
}

@mixin sb-icon-params ($width, $height, $top, $left, $animDelay) {
  width: $width;
  height: $height;
  top: $top;
  left: $left;
  transform: translateX(-200px);
  transition: transform 600ms cubic-bezier(.38,.01,.56,1.48) $animDelay;
}

@mixin sb-icon-params-right ($width, $height, $top, $right, $animDelay) {
  width: $width;
  height: $height;
  top: $top;
  right: $right;
  transform: translateX(200px);
  transition: transform 600ms cubic-bezier(.57,-0.01,.5,1.28) $animDelay;
}

@mixin animateIconTransl {
  &.animate {
    transform: translateX(0px);
  }
}

@mixin border-opacity($width, $color, $opacity) {
  border: $width solid $color;
  border: $width solid rgba($color, $opacity);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box;
}

@mixin border-top-opacity($width, $color, $opacity) {
  border-top: $width solid $color;
  border-top: $width solid rgba($color, $opacity);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box;
}

@mixin border-right-opacity($width, $color, $opacity) {
  border-right: $width solid $color;
  border-right: $width solid rgba($color, $opacity);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box;
}

@mixin border-left-opacity($width, $color, $opacity) {
  border-left: $width solid $color;
  border-left: $width solid rgba($color, $opacity);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box;
}

@mixin border-bottom-opacity($width, $color, $opacity) {
  border-bottom: $width solid $color;
  border-bottom: $width solid rgba($color, $opacity);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box;
}
