.get-started {
  background-color: $white;
  box-shadow: -8px 12px 18px 0 rgba(25,42,70,0.13);
  border-radius: 8px;
  margin-top: -70px;
  padding: 55px 100px;

  @include ipad {
    padding: 50px 40px;
  }

  @include tablet-max {
    padding: 50px 30px;
    text-align: center;
  }
}

.get-started-text {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.2;

  @include ipad {
    font-size: 28px;
  }

  @include tablet-max {
    font-size: 24px;
  }
}