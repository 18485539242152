.engagement {
  margin-top: 60px;

  @include tablet-max {
    margin-top: 40px;
  }
}

.engagement-subtitle {
  max-width: 700px;
  margin: 0 auto;
}

.engagement-title {
  padding: 30px 70px;
  height: 100%;
  background-color: $main-medium;
  color: $white;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 2px 30px 0 rgba(0,0,0,0.3);

  @include tablet-max {
    padding: 30px;
  }
}

.engagement-story {
  padding: 30px;
  height: 100%;
  border-radius: 8px;
  background-color: $white;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.2);
}

.engagement-name {
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 2px;
}

.engagement-date {
  color: $blue-light;
  font-size: 12px;
}

.engagement-avatar {
  min-width: 48px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.engagement-tag {
  background-color: rgba($main, 0.2);
  color: $blue;
  padding: 2px 10px 4px;
  font-size: 11px;
  border-radius: 10px;
  font-weight: 600;
  margin: 0 2px;
}

.engagement-shared {
  font-size: 12px;
  margin-right: 5px;
}

.engagement-column {
  padding: 30px 50px 0;

  @include tablet-max {
    padding: 30px 0 0 15px;
    margin-left: 20px;
    border-left: 5px solid $white;
  }
}

.engagement-text {
  background-color: $white;
  padding: 10px 30px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  margin: 5px 0 10px;
  display: inline-block;
}

.engage-anim-1,
.engage-anim-2,
.engage-anim-3,
.engage-anim-4,
.engage-anim-5,
.engage-anim-6 {
  opacity: 0;
  transition: opacity .3s;
}

.engagement-animation {
  .engage-anim-1,
  .engage-anim-2,
  .engage-anim-3,
  .engage-anim-4,
  .engage-anim-5,
  .engage-anim-6 {
    animation: engageAnimation .6s forwards;
  }

  .engage-anim-2 {
    animation-delay: .3s;
  }

  .engage-anim-3 {
    animation-delay: .7s;
  }

  .engage-anim-4 {
    animation-delay: 1s;
  }

  .engage-anim-5 {
    animation-delay: 1.2s;
  }

  .engage-anim-6 {
    animation-delay: 1.6s;
  }
}

@keyframes engageAnimation {
  0% { 
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
