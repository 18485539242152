.underline {
  text-decoration: underline;
}

.customers-text {
  max-width: 660px;
  margin: 0 auto;
}

.swiper-container {
  width: 100%;
  margin-top: 30px;
  padding: 15px 0;

  @include tablet-max {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
    padding-bottom: 80px;
  }
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;

  @include tablet-max {
    padding: 0 10px;
  }
}

.slide {
  width: calc(100% - 200px);
  height: 100%;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.2);
  padding: 40px;
  background-color: $white;

  @include ipad {
    width: calc(100% - 130px);
  }

  @include tablet-max {
    width: 100%;
  }
}

.swiper-button-next,
.swiper-button-prev {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  color: $white;
  background-color: $main;
  transition: background .2s;

  &:after {
    font-size: 16px;
  }

  &:hover {
    opacity: .8;
  }

  @include tablet-max {
    top: calc(100% - 26px);
  }
}

@include tablet-max {
  .swiper-button-next {
    right: calc(50% - 53px);
  }

  .swiper-button-prev {
    left: calc(50% - 53px);
  }
}
