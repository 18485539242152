@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap');

//font-names
$font-semi:     600;
$font-bold:     700;

$font-family-base:   'Open Sans', Arial, sans-serif;

//media
$desktop-2k:  2400px !default;
$desktop-lg:  1440px !default;
$desktop:     1200px !default;
$laptop:      1024px !default;
$tablet:      768px !default;
$mobile:      480px !default;

// colors
$white:          #fff !default;
$black:          #000 !default;
$dark-blue:      #192038;
$blue:           #205072;
$blue-light:     #7C8CAC;
$grey-light:     #EBF3F3;
$green-light:    #7BE495;
$green-medium:   #56C596;
$green:          #329D9C;
$green-title:    #00B69D;
$orange:         #D94339;
$orange-light:   #E77B68;


$base:           $dark-blue;
$link:           $dark-blue;
$main-light:     $green-light;
$main-medium:    $green-medium;
$main:           $green;
$cta:            $orange;
$cta-light:      $orange-light;

// font sizes
$font-size-big:         36px !default;
$font-size-base:        16px !default;
$font-size-sm:          14px !default;

//container
$padding:               15px !default;
$container-desktop:     (1090px + $padding * 2) !default;
$container-desktop-half:($container-desktop / 2);
$padding-s:               10px !default;
$container-desktop-s:     (1090px + $padding-s * 2) !default;
$container-desktop-half-s:($container-desktop-s / 2);

//other
$anim:          .3s;
$radius:        10px;
